import { useReducer, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GlobalContext } from "states/context";
import { initialGlobalState } from "states/initialStates";
import { globalReducer, initGlobalState } from "states/reducers";
import Loader from "components/elements/loader";
import { NotFound } from "components/elements/notFound";

const Home = lazy(() => import("components/pages/home"));
const Enquadramento = lazy(() => import("components/pages/enquadramento"));
const BusinessOpportunities = lazy(() =>
  import("components/pages/businessOpportunities")
);
const Contacts = lazy(() => import("components/pages/contacts"));
const News = lazy(() => import("components/pages/news"));

function App() {
  const [store, dispatch] = useReducer(
    globalReducer,
    initialGlobalState,
    initGlobalState
  );

  return (
    <div className="app">
      <GlobalContext.Provider value={[store, dispatch]}>
        <Suspense fallback={<Loader />}>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/enquadramento" component={Enquadramento} />
              <Route
                path="/oportunidades-negocio"
                component={BusinessOpportunities}
              />
              <Route path="/contactos" component={Contacts} />
              <Route path="/noticias/:id" component={News} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </Suspense>
      </GlobalContext.Provider>
    </div>
  );
}

export default App;
