export const globalTypes = {
  SET_LANGUAGE: "SET_LANGUAGE",
  UPDATE_TABS: "UPDATE_TABS",
  ENABLE_MOBILE_MENU: "ENABLE_MOBILE_MENU",
  SET_HOME_BANNER_SLIDE: "SET_HOME_BANNER_SLIDE",
  SET_HOME_BANNER_INFO: "SET_HOME_BANNER_INFO",
  SET_HOME_BANNER: "SET_HOME_BANNER",
  SET_HOME: "SET_HOME",
  SET_PAGE_DATA: "SET_PAGE_DATA",
  RESET: "RESET",
};
