import { useContext } from "react";
import classnames from "classnames";
import { GlobalContext } from "states/context";
import styles from "styles/default.module.scss";

const texts = {
  info: {
    pt: "Página não encontrada.",
    en: "Page not found.",
  },
  link: {
    pt: "Voltar para a Página Inicial.",
    en: "Return to Home Page.",
  },
};

export const NotFound = () => {
  const [{ language }] = useContext(GlobalContext);

  return (
    <div className={styles.notFount}>
      <div className={styles.container}>
        <i className={classnames("flaticons-error", styles.icon)}></i>
        <span className={styles.info}>{texts.info[language]}</span>
        <span
          className={styles.link}
          onClick={() => {
            window.location.href = "/";
          }}
        >
          {texts.link[language]}
        </span>
      </div>
    </div>
  );
};
