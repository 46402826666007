import { globalTypes } from "./global.types";

export const initGlobalState = (initialGlobalState) => {
  return initialGlobalState;
};

export const globalReducer = (state, action) => {
  switch (action.type) {
    case globalTypes.SET_LANGUAGE:
      return { ...state, language: action.value };
    case globalTypes.UPDATE_TABS:
      return { ...state, updateTabs: !state.updateTabs };
    case globalTypes.ENABLE_MOBILE_MENU:
      return {
        ...state,
        enableMobileMenu:
          typeof action.value === "boolean"
            ? action.value
            : !state.enableMobileMenu,
      };
    case globalTypes.SET_HOME_BANNER_SLIDE:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          home: {
            ...state.pageData.home,
            banner: {
              ...state.pageData.home.banner,
              slide: action.data,
            },
          },
        },
      };
    case globalTypes.SET_HOME_BANNER_INFO:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          home: {
            ...state.pageData.home,
            banner: {
              ...state.pageData.home.banner,
              blockInfo: action.data,
            },
          },
        },
      };
    case globalTypes.SET_HOME_BANNER:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          home: {
            ...state.pageData.home,
            banner: {
              ...state.pageData.home.banner,
              ...action.data,
            },
          },
        },
      };
    case globalTypes.SET_HOME:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          home: {
            ...state.pageData.home,
            ...action.data,
          },
        },
      };
    case globalTypes.SET_PAGE_DATA:
      return {
        ...state,
        pageData: {
          ...state.pageData,
          ...action.data,
        },
      };
    case globalTypes.RESET:
      return initGlobalState(action.payload);
    default:
      throw new Error();
  }
};
