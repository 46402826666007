export const initialGlobalState = {
  language: "pt",
  pageData: {
    home: {
      banner: {
        slide: [],
        blockInfo: [],
        hasBlockInfo: false,
        socialNetworks: [],
        hasSocialNetworks: false,
      },
      proprivBlock: {},
      publicity: {},
      multimedia: [],
    },
    hasHomeData: false,
    overview: {
      banner: {},
      context: {},
      legalRegime: [],
      procedureModalities: {},
      location: {},
    },
    hasOverview: false,
    news: [],
    hasNews: false,
    sectors: [],
    hasSectors: false,
    assets: [],
    hasAssets: false,
    banners: [],
    hasBanners: false,
    disposals: [],
    hasDisposals: false,
    hasSectorPages: false,
    siteInformation: {},
    hasSiteInformation: false,
  },
};
